<template>
  <VFlex class="menu-legend--attr asap--font" v-bind="$attrs">
    <VLayout row>
      <VFlex shrink>
        <MenuItemAttr :attr="attr" class="static--forced" />
      </VFlex>
      <VFlex v-if="attr.desc" mt-1>
        {{ attr.desc }}
      </VFlex>
    </VLayout>
  </VFlex>
</template>

<script>
import MenuItemAttr from '@menu/MenuItemAttr'
export default {
  name: 'MenuLegendAttr',
  components: { MenuItemAttr },
  props: {
    attr: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {}
  },
}
</script>
